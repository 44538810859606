import React, { FunctionComponent, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";

import {
  makeStyles,
  TableRow,
  TableCell,
  Checkbox,
  TextField,
  CircularProgress,
  Tooltip,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MapIcon from "@material-ui/icons/Map";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import { ReactComponent as Tractor } from "../../images/tractor.svg";

import { Colors } from "../../theme/colors";
import { IFileDto } from "../../store/data-storage/data-storage.store";
import { FileTypes, TitlesByType } from "../../shared/accordion-titles";
import TableComponent from "../common/table/table.component";
import DeleteConfirmationWindowContainer from "../modal-windows/delete-confirmation-window/delete-confirmation-window.container";
import { ValidationStatus } from "../../shared/validation-statuses";
import { useDispatch } from "react-redux";
import { changeFileType } from "../../store/data-storage";
import AuthService from "../../shared/services/auth.service";
import moment from "moment";
import { CREATED_AT_FORMAT } from "../../shared/time-formats";

export interface ConnectedState {
  editableFile?: any;
  validationProgress?: boolean;
}

export interface ConnectedDispatch {
  toggleEditMode?: (isActivated: boolean, fileId: string, fileName: string) => void;
  downloadFile?: (id: string, fileName: string) => void;
  changeFileName?: (fileName: string, fileId: string) => void;
  validateFile?: (id: string) => void;
  toggleValidationModalWindow?: (isValidModalOpen: boolean) => void;
}

export interface Props extends ConnectedDispatch, ConnectedState {
  files: IFileDto[];
  onUpdateSelected: (e: any, id: string, state: boolean) => void;
  panelName?: string;
  onClearSelected?: (id: string[]) => void;
  allSelectedFiles?: string[];
  onRememberValidatingFile?: (id: string) => void;
  validatingFile?: string;
  hasControls?: boolean;
}

export enum ValidationMessage {
  NO_ERROR = "No Errors found",
  WARNING = "WARNINGS",
  FATAL = "FATAL",
  ERROR = "ERROR",
}

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: `${Colors.Grey}`,
    fontSize: "25px",
    "&:hover": {
      color: `${Colors.DarkGrey}`,
    },
  },
  downloadButton: {
    color: `${Colors.Grey}`,
    fontSize: "25px",
    "&:hover": {
      color: `${Colors.DarkGrey}`,
    },
    marginLeft: "8px",
  },
  telemetryButton: {
    width: 30,
    height: 30,
    padding: "0 3px",
    marginLeft: "8px",
    borderRadius: 0,
    color: Colors.Grey,
    backgroundColor: "transparent",
    border: "none",
    "&:hover": {
      backgroundColor: "transparent",
      color: Colors.DarkGrey,
    },
    "&:active, &:focus": {
      backgroundColor: Colors.LightGrey,
    },
  },
  editActionButton: {
    color: `${Colors.Grey}`,
    fontSize: "25px",
    "&:hover": {
      color: `${Colors.DarkGrey}`,
    },
    marginLeft: "10px",
  },
  actionsSection: {
    display: "flex",
    justifyContent: "flex-end",
  },
  problemIcon: {
    color: Colors.Orange,
  },
  acceptIcon: {
    color: Colors.Green2,
  },
  fileName: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "0.875rem",
    cursor: "pointer",
    alignItems: "center",
  },
  editableField: {
    width: "100%",
  },
  telemetryLink: {
    color: theme.palette.primary.main,

    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  fatalIcon: {
    color: Colors.Red,
  },
  warningIcon: {
    color: Colors.Yellow,
  },
  shouldValidateIcon: {
    color: Colors.Grey,
  },
  typeSelector: {
    "& > .MuiOutlinedInput-input": {
      padding: "4px 30px 4px 8px",
    },
  },
  simulateButton: {
    color: `${Colors.Grey}`,
    marginLeft: 8,

    "&:hover": {
      color: `${Colors.DarkGrey}`,
    },
  },
}));

const FilesTableComponent: FunctionComponent<Props> = ({
  files,
  panelName,
  allSelectedFiles,
  validateFile,
  validationProgress,
  onRememberValidatingFile,
  validatingFile,
  toggleValidationModalWindow,
  hasControls = true,
  ...actions
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isEndUser = AuthService.getInstance().isEndUser();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [editableFileName, setEditableFileName] = useState("");
  const [fileIds, setFileIds] = useState<string[]>([]);

  const selectFile = (e: any, id: string, state: boolean) => {
    actions.onUpdateSelected(e, id, state);
  };

  const downloadCurrentFile = (id: string, filename: string) => {
    if (actions.downloadFile) {
      actions.downloadFile(id, filename);
    }
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationOpen(false);
  };

  const openConfirmationDialog = (id: string) => {
    setFileIds([id]);
    setIsConfirmationOpen(true);
  };

  const openValidationDialog = (fileId: string) => {
    // tslint:disable-next-line: no-unused-expression
    !!onRememberValidatingFile && onRememberValidatingFile(fileId);
    // tslint:disable-next-line: no-unused-expression
    !!validateFile && validateFile(fileId);
  };

  const isXMLpanel = panelName === FileTypes.ISOXML || panelName === FileTypes.EFDI_TIMELOG;
  const telemetryIsAvailable = panelName === FileTypes.EFDI_TIMELOG || panelName === FileTypes.GPS;
  const isSimulationAvailable =
    panelName === FileTypes.EFDI_TIMELOG || panelName === FileTypes.GPS || panelName === FileTypes.ISOXML;

  const activateEditMode = (id: string, fileName: string) => {
    if (actions.toggleEditMode) {
      actions.toggleEditMode(true, id, fileName);
    }
    setEditableFileName(fileName);
  };

  const deactivateEditMode = () => {
    if (actions.toggleEditMode) {
      actions.toggleEditMode(false, "", "");
    }
  };

  const handleInputField = (e: any) => {
    setEditableFileName(e.target.value);
  };

  const onKeyPress = (e: any) => {
    if (e.keyCode === 13 && editableFileName !== actions.editableFile.fileName) {
      setEditableFileName(e.target.value);
      // tslint:disable-next-line: no-unused-expression
      if (actions.changeFileName) {
        actions.changeFileName(e.target.value, actions.editableFile.fileId);
      }
      deactivateEditMode();
    }
  };

  const isValidating = (id: string) => {
    return validationProgress && !!validatingFile && validatingFile === id;
  };

  const handleTypeChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>, id: string) => {
      dispatch(changeFileType(id, TitlesByType[event.target.value as string]));
    },
    [dispatch]
  );

  const renderTableHead = () => (
    <TableRow>
      <TableCell />
      <TableCell>Name</TableCell>
      <TableCell align="right">Size</TableCell>
      <TableCell align="right">Created at</TableCell>
      <TableCell align="right">Type</TableCell>
      {actions.downloadFile && typeof actions.downloadFile === "function" && (
        <TableCell align="right">Actions</TableCell>
      )}
      {isXMLpanel && <TableCell align="center">Validation</TableCell>}
    </TableRow>
  );

  const renderTableBody = () =>
    files.map(({ id, type, size, filename, validation, lastmodified, source, created }: IFileDto) => (
      <TableRow key={id}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={!!allSelectedFiles && allSelectedFiles.includes(id)}
            onClick={(event) => selectFile(event, id, !(!!allSelectedFiles && allSelectedFiles.includes(id)))}
            inputProps={{ "aria-labelledby": id }}
            role="selection-checkbox"
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <div className={classes.fileName}>
            {!!actions.editableFile &&
            actions.editableFile.isEditModeActivated &&
            actions.editableFile.fileId === id ? (
              <>
                <TextField
                  className={classes.editableField}
                  type="text"
                  value={editableFileName}
                  onChange={handleInputField}
                  onKeyDown={onKeyPress}
                />
                <CloseIcon className={classes.editActionButton} onClick={() => deactivateEditMode()} />
              </>
            ) : (
              <>
                <Tooltip
                  title={
                    <div>
                      Id: {id} <br />
                      Source: {source} <br />
                      {!!validation && (
                        <>
                          Valid: {validation} <br />
                        </>
                      )}
                      Created: {moment(created).format(CREATED_AT_FORMAT)} <br />
                      Last Modified: {lastmodified}
                    </div>
                  }
                  placement="bottom"
                >
                  <Typography
                    className={classes.fileName}
                    onClick={(event) =>
                      selectFile(event, id, !(!!allSelectedFiles && allSelectedFiles.includes(id)))
                    }
                  >
                    {filename}
                  </Typography>
                </Tooltip>

                <EditIcon
                  className={classes.editActionButton}
                  onClick={() => activateEditMode(id, filename)}
                />
              </>
            )}
          </div>
        </TableCell>
        <TableCell align="right">{size}</TableCell>
        <TableCell align="right">{moment(created).format(CREATED_AT_FORMAT)}</TableCell>
        <TableCell align="right">
          {isEndUser ? (
            type
          ) : (
            <Select
              value={type}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => handleTypeChange(e, id)}
              variant="outlined"
              className={classes.typeSelector}
            >
              {Object.values(FileTypes).map((fileType: string) => (
                <MenuItem key={fileType} value={fileType}>
                  {TitlesByType[fileType]}
                </MenuItem>
              ))}
            </Select>
          )}
        </TableCell>
        {hasControls && (
          <TableCell align="left">
            {actions.downloadFile && typeof actions.downloadFile === "function" && (
              <div className={classes.actionsSection}>
                <Tooltip title="Delete" placement="top" arrow={true}>
                  <DeleteForeverIcon
                    className={classes.deleteButton}
                    onClick={() => openConfirmationDialog(id)}
                  />
                </Tooltip>
                <Tooltip title="Download" placement="top" arrow={true}>
                  <CloudDownloadIcon
                    className={classes.downloadButton}
                    onClick={() => downloadCurrentFile(id, filename)}
                  />
                </Tooltip>

                {isSimulationAvailable && (
                  <Tooltip title="Simulate" placement="top" arrow={true}>
                    <NavLink to={`/simulation?id=${id}`} className={classes.simulateButton}>
                      <Tractor />
                    </NavLink>
                  </Tooltip>
                )}

                {telemetryIsAvailable && (
                  <Tooltip title="Telemetry" placement="top" arrow={true}>
                    <NavLink to={`/data-storage/telemetry/${id}`} className={classes.telemetryButton}>
                      <MapIcon />
                    </NavLink>
                  </Tooltip>
                )}
              </div>
            )}
          </TableCell>
        )}
        {isXMLpanel && (
          <TableCell align="center">
            {isValidating(id) ? (
              <CircularProgress size={24} />
            ) : !!validation && validation === ValidationStatus.ERRORS ? (
              <ReportProblemIcon className={classes.problemIcon} onClick={() => openValidationDialog(id)} />
            ) : !!validation && validation === ValidationStatus.NO_ERROR ? (
              <CheckCircleIcon className={classes.acceptIcon} onClick={() => openValidationDialog(id)} />
            ) : !!validation && validation === ValidationStatus.WARNINGS ? (
              <ReportProblemIcon className={classes.warningIcon} onClick={() => openValidationDialog(id)} />
            ) : !!validation && validation === ValidationStatus.FATAL_ERRORS ? (
              <ErrorIcon className={classes.fatalIcon} onClick={() => openValidationDialog(id)} />
            ) : (
              <CheckCircleIcon
                className={classes.shouldValidateIcon}
                onClick={() => openValidationDialog(id)}
              />
            )}
          </TableCell>
        )}
      </TableRow>
    ));

  return (
    <>
      <TableComponent tableHead={renderTableHead()} tableBody={renderTableBody()} />
      {isConfirmationOpen && (
        <DeleteConfirmationWindowContainer
          closeModal={closeConfirmationDialog}
          deletedFileIds={fileIds}
          open={isConfirmationOpen}
          onClearSelected={actions.onClearSelected}
        />
      )}
    </>
  );
};

export default FilesTableComponent;
