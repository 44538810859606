import axios, { AxiosInstance } from "axios";
import { ApiControllers, ApiEndpoints } from "./api-endpoints";
import { ExportParams } from "../pages/simulation/simulation.component";
import qs from "qs";

export class BackendApi {
  private instance: AxiosInstance;
  private backendUrl: string;

  constructor(backendUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();

    this.backendUrl = backendUrl ?? "";
  }

  auth = async (state: string, code: string) =>
    await this.instance.get(
      `${this.backendUrl}${ApiControllers.AUTH}${ApiEndpoints.CALLBACK}?state=${state}&code=${code}`
    );

  getUrl = async () => {
    deleteCookieHeader();
    const { data } = await axios.get(`${this.backendUrl}${ApiControllers.AUTH}${ApiEndpoints.URL}`);

    return data;
  };

  updateToken = async (refreshToken: string) =>
    await this.instance.post(`${this.backendUrl}${ApiControllers.AUTH}${ApiEndpoints.REFRESH}`, refreshToken);

  uploadFile = async (file: any) => {
    const formData = new FormData();
    formData.append("files", file);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return await this.instance.post(
      `${this.backendUrl}${ApiControllers.FILES}${ApiEndpoints.UPLOAD}`,
      formData,
      config
    );
  };

  downloadFile = async (fileId: string) => {
    const {
      data: file,
    } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.FILES}${ApiEndpoints.DOWNLOAD}/${fileId}`,
      { responseType: "arraybuffer" }
    );
    return { file };
  };

  renameFile = async (fileId: string, newName: string) => {
    return await this.instance.put(
      `${this.backendUrl}${ApiControllers.FILES}/${fileId}/name?newName=${newName}`
    );
  };

  changeFileType = async (fileId: string, newType: string) => {
    return await this.instance.put(
      `${this.backendUrl}${ApiControllers.FILES}/${fileId}/type?newType=${newType}`
    );
  };

  deleteFiles = async (fileIds: string[]) =>
    await this.instance.delete(`${this.backendUrl}${ApiControllers.FILES}`, { data: fileIds });

  getFileList = async () => {
    const { data } = await this.instance.get(`${this.backendUrl}${ApiControllers.FILES}`);
    return data;
  };

  downloadIsoxml = async (fileId: string) => {
    return await this.instance.get(
      `${this.backendUrl}${ApiControllers.FILES}${ApiEndpoints.ISOXML}/${fileId}`
    );
  };

  exportEfdi = async (id: string, params: ExportParams) => {
    const { data } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.FILES}${ApiEndpoints.EFDI}/${id}?${qs.stringify(params)}`
    );
    return data;
  };

  justifyOnboard = async (pathname: string, search: string) =>
    await this.instance.get(`${this.backendUrl}${pathname}${search}`);

  logout = async (idToken: string) =>
    await this.instance.post(`${this.backendUrl}${ApiControllers.AUTH}${ApiEndpoints.LOGOUT}`, idToken);

  fetchLoggingStatus = async () => {
    const {
      data: { enabled },
    } = await this.instance.get(`${this.backendUrl}${ApiControllers.LOGS}${ApiEndpoints.LOGGING_STATUS}`);
    return enabled;
  };

  changeLoggingStatus = async (enable: boolean) => {
    return await this.instance.post(
      `${this.backendUrl}${ApiControllers.LOGS}${ApiEndpoints.LOGGING_STATUS}?enable=${enable}`
    );
  };

  getUserLogsEntries = async () => {
    const { data } = await this.instance.get(`${this.backendUrl}${ApiControllers.LOGS}`);
    return data;
  };

  fetchTraceLoggingStatus = async () => {
    const {
      data: { enabledTill },
    } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.LOGS}${ApiEndpoints.TRACE_LOGGING_STATUS}`
    );
    return enabledTill;
  };

  changeTraceLoggingStatus = async (enable: boolean) => {
    const {
      data: { enabledTill },
    } = await this.instance.post(
      `${this.backendUrl}${ApiControllers.LOGS}${ApiEndpoints.TRACE_LOGGING_STATUS}?enable=${enable}`
    );
    return enabledTill;
  };

  clearLogs = async () =>
    await this.instance.delete(`${this.backendUrl}${ApiControllers.LOGS}${ApiEndpoints.CLEAR_LOGS}`);

  validate = async (id: string) => {
    const { data } = await this.instance.post(`${this.backendUrl}${ApiEndpoints.VALIDATE}/${id}`);

    return data;
  };

  telemetry = async (fileId: string, offset: number, limit = -1) => {
    const { data } = await this.instance.post(`${this.backendUrl}${ApiEndpoints.TELEMETRY}`, {
      fileId,
      offset,
      limit,
    });

    return data;
  };

  receiveFiles = async (recentOnly: boolean) => {
    const { data } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_FILES}${ApiEndpoints.RECEIVE}?recentOnly=${recentOnly}`
    );

    return data;
  };

  clearFeed = async () => {
    const { data } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_FILES}${ApiEndpoints.CLEAR_FEED}`
    );

    return data;
  };

  sendFiles = async (fileIds: string[], targets: string[]) =>
    await this.instance.post(`${this.backendUrl}${ApiControllers.AGRIROUTER_FILES}${ApiEndpoints.SEND}`, {
      fileIds,
      targets,
    });

  publishFiles = async (fileIds: string[], targets: string[]) =>
    await this.instance.post(`${this.backendUrl}${ApiControllers.AGRIROUTER_FILES}${ApiEndpoints.PUBLISH}`, {
      fileIds,
      targets,
    });

  setCapabilities = async (capabilities: Capability[], pushNotifications: number) =>
    await this.instance.post(`${this.backendUrl}${ApiControllers.AGRIROUTER_CAPABILITIES}`, {
      capabilities,
      pushNotifications,
    });

  getCapabilities = async () => {
    const { data } = await this.instance.get(`${this.backendUrl}${ApiControllers.AGRIROUTER_CAPABILITIES}`);
    return data;
  };

  getEndpoints = async () => {
    const { data } = await this.instance.get(`${this.backendUrl}${ApiControllers.AGRIROUTER_ENDPOINTS}`);

    return data;
  };

  updateEndpoints = async () =>
    await this.instance.post(`${this.backendUrl}${ApiControllers.AGRIROUTER_ENDPOINTS}`);

  getOnboardStatus = async () => {
    const { data } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_ONBOARDING}${ApiEndpoints.STATUS}`
    );

    return data;
  };

  onboard = async () =>
    await this.instance.get(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_ONBOARDING}${ApiEndpoints.CALLBACK}`
    );

  getOnboardUrl = async (isMqtt: boolean, isQa: boolean) =>
    await this.instance.get(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_ONBOARDING}${ApiEndpoints.ONBOARD_URL}?isMqtt=${isMqtt}&isQa=${isQa}`
    );

  getReonboardUrl = async () =>
    await this.instance.get(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_ONBOARDING}${ApiEndpoints.REONBOARD_URL}`
    );

  reconnect = async () =>
    await this.instance.post(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_ONBOARDING}${ApiEndpoints.RECONNECT}`
    );

  offboard = async () =>
    await this.instance.post(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_ONBOARDING}${ApiEndpoints.OFFBOARD}`
    );

  setSubscriptions = async (subscriptions: Subscription[]) =>
    await this.instance.post(`${this.backendUrl}${ApiControllers.AGRIROUTER_SUBSCRIPTIONS}`, subscriptions);

  getSubscriptions = async () => {
    const { data } = await this.instance.get(`${this.backendUrl}${ApiControllers.AGRIROUTER_SUBSCRIPTIONS}`);
    return data;
  };

  getListFormats = async () => await this.instance.get(`${this.backendUrl}${ApiControllers.FORMATS}`);

  getSimulationProgress = async () => {
    const { data } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.SIMULATOR}${ApiEndpoints.PROGRESS}`
    );
    return data;
  };

  getSimulatorFileList = async () => {
    const { data } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.SIMULATOR}${ApiEndpoints.FILES}`
    );
    return data;
  };

  getTimeLogInfo = async (fileId: string) => {
    const { data } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.SIMULATOR}${ApiEndpoints.TIMELOG_INFO}?fileId=${fileId}`
    );
    return data;
  };

  simulate = async (simulateRequestBody: IStartSimulate) => {
    const { data } = await this.instance.post(
      `${this.backendUrl}${ApiControllers.SIMULATOR}${ApiEndpoints.SIMULATE}`,
      simulateRequestBody
    );
    return data;
  };

  pause = async () => {
    const { data } = await this.instance.post(
      `${this.backendUrl}${ApiControllers.SIMULATOR}${ApiEndpoints.PAUSE}`
    );
    return data;
  };

  resume = async () => {
    const { data } = await this.instance.post(
      `${this.backendUrl}${ApiControllers.SIMULATOR}${ApiEndpoints.RESUME}`
    );
    return data;
  };

  stop = async () =>
    await this.instance.post(`${this.backendUrl}${ApiControllers.SIMULATOR}${ApiEndpoints.STOP}`);

  forward = async () => {
    const { data } = await this.instance.post(
      `${this.backendUrl}${ApiControllers.SIMULATOR}${ApiEndpoints.FORWARD}`
    );
    return data;
  };

  getEnvironment = async () => {
    const { data } = await this.instance.get(
      `${this.backendUrl}${ApiControllers.AGRIROUTER_ONBOARDING}${ApiEndpoints.ENVIRONMENT}`
    );
    return data;
  };
}

const deleteCookieHeader = () => {
  if (document.cookie) {
    const theCookies = document.cookie.split(";");
    for (const cookie of theCookies) {
      const cookieName = cookie.split("=")[0];
      document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
  }
};

export enum CapabilityDirection {
  SEND = 0,
  RECEIVE = 1,
  SEND_AND_RECEIVE = 2,
}

export interface Capability {
  direction?: CapabilityDirection;
  name: string;
  type: string;
}

export interface Subscription {
  name: string;
  type: string;
  active: boolean;
}

export const CAPABILITY_DIRECTION = ["Send", "Receive", "Send and Receive"];
export const PUSH_NOTIFICATIONS = ["Disabled", "Enabled", "Enabled (High frequency)"];

export interface LogEntry {
  text: string;
  time: string;
  type: string;
  traceInfo?: any;
}

export enum LogEntryType {
  ERROR = "error",
  INFO = "info",
  TRACE = "trace",
}

export interface APIError {
  code?: number;
  message: string;
}

export interface OnboardParams {
  qa?: boolean;
  mqtt?: boolean;
}

export interface StatusData {
  expireDays: number | null;
  onboarded: boolean;
  onboarding: boolean;
  qa: boolean;
  mqtt: boolean;
  pendingEndpoints: boolean;
  accountId: string | undefined;
  endpointId: string | undefined;
}

export enum UserRoleType {
  USER = "User",
  ADMIN = "Admin",
}

export interface IStartSimulate {
  fileId: string;
  timeLogName: string;
  skip: number;
  interval: number;
  scale: number;
  replaceTime: string;
  endless: boolean;
}
